import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Router from './Router'

import { CursorProvider } from './context/CursorContext'

ReactDOM.render(
  <React.StrictMode>
    <CursorProvider>
      <Router />
    </CursorProvider>
  </React.StrictMode>,
  document.getElementById('root')
);