import React from "react";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

function Page({ children }) {
	return (
		<div>
			<motion.div>{children}</motion.div>
		</div>
	);
}

export default Page;
