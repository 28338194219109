import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

function UnderlinedText({ text, lineColor = "#6a7343", delay = 1, duration = 1, style, onMouseEnter, onMouseLeave}) {
	return (
		<TextContainer lineColor={lineColor} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<motion.p
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
					transition: {
						ease: "easeInOut",
						delay: delay,
						duration: duration,
					},
				}}
			>
				{text}
			</motion.p>
			<motion.span
				initial={{
					right: "100%",
				}}
				animate={{
					right: 0,
					transition: {
						ease: "easeInOut",
						delay: delay + 0.2,
						duration: duration - 0.2,
					},
				}}
			></motion.span>
		</TextContainer>
	);
}

export default UnderlinedText;

const TextContainer = styled.div`
	display: inline-block;
	position: relative;

	p {
		margin: 0;
        font-weight: 500;
	}

	span {
		position: absolute;
		bottom: -2px;
		left: 0;
		right: 0;
		height: 2px;
		background-color: ${(props) => props.lineColor};
	}
`;
