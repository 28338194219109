import React from "react";
import Page from "../components/Page";
import { motion } from "framer-motion";
import styled from 'styled-components';
import SlicingText from "../components/SlicingText"
import UnderlinedText from "../components/UnderlinedText"
import useCursor from "../hooks/useCursor"

function Home() {
	const { startHover, stopHover } = useCursor();

	return (
		<Page>
			<Background/>
			<MainContainer>
				<div data-scroll data-scroll-speed="3">
					<SlicingText style={{fontSize: "8rem"}} delay={.5} text="Experience your"/>
					<SlicingText style={{fontSize: "8rem"}} delay={.6} text="next chapter."/>
				</div>

				<UnderlinedText onMouseEnter={startHover} onMouseLeave={stopHover} style={{marginTop: "4rem", fontSize: "1.5rem", cursor: "pointer"}} text="Find out more"/>

				<DescriptionText>
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus omnis sapiente ex reiciendis, itaque inventore quae sequi molestias dolorem optio.
				</DescriptionText>
			</MainContainer>
			<section style={{
				height: "100vh",
				width: "100vw",
			}}>

			</section>
		</Page>
	);
}

function Background() {
	return (
		<div className="background">
			<motion.img
				initial={{
					scale: 1.2,
				}}
				animate={{
					scale: 1,
					transition: {
						ease: "easeInOut",
						duration: 1,
						delay: 1,
					},
				}}
				src="https://images.unsplash.com/photo-1456694441711-af0ab2d64c96?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
			></motion.img>
		</div>
	);
}

export default Home;

const MainContainer = styled(motion.div)`
	width: 100vw;
	height: 100vh;
	padding: 12rem 6rem 6rem 6rem;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`
const DescriptionText = styled(motion.p)`
	width: 30%;
	line-height: 2rem;
	margin-top: auto;
`