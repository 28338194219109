import React from "react";
import { motion } from "framer-motion";

const container = {
	animate: {
		transition: {
			delayChildren: 0.4,
			staggerChildren: 0.1,
		},
	},
};

const letterAnimation = {
	initial: {
		y: 100,
	},
	animate: {
		y: 0,
		transition: {
			ease: [0.6, 0.01, -0.05, 0.95],
			duration: 1,
		},
	},
};

function AnimatedLetters({ text, onAnimationComplete }) {
	return (
		<motion.div className="animated-letters-wrapper">
			<motion.span
				className="animated-letter-container"
				variants={container}
				initial="initial"
				animate="animate"
				onAnimationComplete={onAnimationComplete}
			>
				{[...text].map((letter, index) => (
					<motion.span className="animated-letter" variants={letterAnimation} key={index}>
						{letter}
					</motion.span>
				))}
			</motion.span>
		</motion.div>
	);
}

export default AnimatedLetters;
