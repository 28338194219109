import React, { useRef, useState, useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";

const useLocomotive = (ref) => {
	const locomotiveScrollRef = useRef();

	useEffect(() => {
		if (ref.current) {
			locomotiveScrollRef.current = new LocomotiveScroll({
				el: ref.current,
				smooth: true,
                lerp: 0.08,
			});
		}

		return () => {
			locomotiveScrollRef.current.destroy();
		}
	}, [ref]);
	return [locomotiveScrollRef];
}

const useLocomotiveScroll = (ref, onScroll = () => {}) => {
	const [locomotiveState, setLocomotiveState] = useState();

	const [scroll, setScroll] = useState({
		scrollY: undefined,
		scrollX: undefined,
	});

	useEffect(() => {
		if (locomotiveState) {
			locomotiveState.on("scroll", (e) => {
				setScroll({
					scrollY: e.scroll.y,
					scrollX: e.scroll.x,
				});
				onScroll(e);
			})
		}
	}, locomotiveState);

	const updateLocomotive = () => {
		if (ref) {
			setLocomotiveState(ref.current);
		}
	}

	return [scroll, updateLocomotive];
}

export { useLocomotive, useLocomotiveScroll }