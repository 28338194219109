import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import useCursor from "../hooks/useCursor";

function Cursor({ visible }) {
	const { hovering } = useCursor();
	const secondaryCursor = useRef();
	const positionRef = useRef({
		mouseX: 0,
		mouseY: 0,
		destinationX: 0,
		destinationY: 0,
		distanceX: 0,
		distanceY: 0,
		key: -1,
	});
	const [inView, setInView] = useState(true);
	const [hasMoved, setHasMoved] = useState(false);

	useEffect(() => {
		document.addEventListener("mousemove", (event) => {
			const { clientX, clientY } = event;

			const mouseX = clientX;
			const mouseY = clientY;

			if (secondaryCursor.current) {
				positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
			}
			if (secondaryCursor.current) {
				positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;
			}

			setHasMoved(true);
		});

		document.addEventListener("mouseleave", (e) => {
			setInView(false);
		});

		document.addEventListener("mouseenter", (e) => {
			setInView(true);
		});
	}, []);

	useEffect(() => {
		const followMouse = () => {
			positionRef.current.key = requestAnimationFrame(followMouse);
			const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } = positionRef.current;
			if (!destinationX || !destinationY) {
				positionRef.current.destinationX = mouseX;
				positionRef.current.destinationY = mouseY;
			} else {
				positionRef.current.distanceX = (mouseX - destinationX) * 0.08;
				positionRef.current.distanceY = (mouseY - destinationY) * 0.08;
				if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
					positionRef.current.destinationX = mouseX;
					positionRef.current.destinationY = mouseY;
				} else {
					positionRef.current.destinationX += distanceX;
					positionRef.current.destinationY += distanceY;
				}
			}
			if (secondaryCursor && secondaryCursor.current)
				secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
		};
		followMouse();
	}, []);

	return (
		<CustomCursor visible={!hasMoved ? false : inView} hovering={hovering} ref={secondaryCursor}>
			<div></div>
		</CustomCursor>
	);
}

export default Cursor;

const CustomCursor = styled.div`
	pointer-events: none;
	position: fixed;
	mix-blend-mode: difference;
	z-index: 2;
	transition: opacity 1s ease;
    
	opacity: ${(props) => (props.visible ? 1 : 0)};

    
	div {
        z-index: 1;
		background-color: white;
		border-radius: 50%;
        width: 40px;
        height: 40px;
        transition: transform .3s ease;
        transform: ${props => props.hovering ? "scale(2)" : "scale(1)"};
	}
`;
