import React, { createContext, useState } from 'react'

const CursorContext = createContext({
    hovering: "",
    startHover: () => {},
    stopHover: () => {}
})

const CursorProvider = ({children}) => {
    const [cursorState, setCursorState] = useState({
        hovering: ""
    });

    const startHover = () => {
        setCursorState({
            ...cursorState,
            hovering: true,
        });
    }

    const stopHover = () => {
        setCursorState({
            ...cursorState,
            hovering: false,
        });
    }

    return (
        <CursorContext.Provider value={{
            ...cursorState,
            startHover,
            stopHover
        }}>
            {children}
        </CursorContext.Provider>
    )
}

export { CursorProvider, CursorContext }