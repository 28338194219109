import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useCursor from "../hooks/useCursor";

const container = {
	animate: {
		transition: {
			delayChildren: 3,
			staggerChildren: 0.2,
		},
	},
};

const item = {
	initial: {
		y: -40,
		opacity: 0,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			ease: "easeInOut",
			duration: 1,
		},
	},
};

function Navbar({ locomotive }) {
	const [scrolled, setScrolled] = useState(false);
	const { startHover, stopHover } = useCursor();

	useEffect(() => {
		if (locomotive) {
			locomotive.on("scroll", (e) => {
				setScrolled(e.scroll.y >= 10);
			})
		}
	}, locomotive);

	return (
		<NavbarContainer $scrolled={scrolled} variants={container} initial="initial" animate="animate">
			<motion.a onMouseEnter={startHover} onMouseLeave={stopHover} variants={item} href="">
				DISCOVER
			</motion.a>
			<motion.div variants={item}>
				<a onMouseEnter={startHover} onMouseLeave={stopHover} href="">About</a>
				<a onMouseEnter={startHover} onMouseLeave={stopHover} href="">Stories</a>
				<a onMouseEnter={startHover} onMouseLeave={stopHover} href="">Learn</a>
				<a onMouseEnter={startHover} onMouseLeave={stopHover} href="">Growth</a>
			</motion.div>
			<motion.a onMouseEnter={startHover} onMouseLeave={stopHover} variants={item} href="">
				Contact
			</motion.a>
		</NavbarContainer>
	);
}

export default Navbar;

const NavbarContainer = styled(motion.div)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	transition: padding .3s ease, background .3s ease, backdrop-filter .3s ease;
	${props => `
		padding: ${props.$scrolled ? "2rem 6rem" : "4rem 6rem"};
		background: ${props.$scrolled ? "rgba(0 0 0 / 25%)" : ""};
		backdrop-filter: ${props.$scrolled ? "blur(5px)" : ""};
	`}

	a {
		color: white;
		text-decoration: none;
	}

	div {
		display: flex;
		justify-content: space-between;

		a {
			padding: 0 2rem;
		}
	}
`;
