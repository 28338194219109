import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useLocomotive } from "./hooks/useLocomotive";
import Navbar from "./components/Navbar";

import Loader from "./components/Loader";
import Home from "./pages/Home";
import Cursor from "./components/Cursor";

function Router() {
	const [loading, setLoading] = useState(true);

	const ref = useRef();
	const [locomotiveScroll] = useLocomotive(ref);
	const [locomotiveStart, setLocomotive] = useState(false);

	useEffect(() => {
		if (locomotiveStart && locomotiveScroll.current) {
			locomotiveScroll.current.update();
		}
	}, [locomotiveStart]);

	return (
		<BrowserRouter>
		<Navbar locomotive={locomotiveScroll.current}/>
		<Cursor/>
		<div ref={ref} data-scroll-container>
			<AnimatePresence>
				{loading ? (
					<motion.div
						key="loader"
						initial={{ y: 0 }}
						animate={{ y: 0 }}
						exit={{
							y: "100vh",
							transition: {
								ease: "easeInOut",
								duration: 1,
							},
						}}
					>
						<Loader setLoading={setLoading} setLocomotive={setLocomotive} />
					</motion.div>
				) : (
					<Routes>
						<Route path="/" element={<Home loading={loading}/>} />
					</Routes>
				)}
			</AnimatePresence>
		</div>
		</BrowserRouter>
	);
}

export default Router;
