import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import AnimatedLetters from "./AnimatedLetters";

function Loader({ setLoading, setLocomotive }) {
	const [visible, setVisible] = useState(true);

	return (
		<motion.div className="loader">
			<AnimatePresence>
				{visible && (
					<motion.div
						key="text"
						exit={{ opacity: 0 }}
						onAnimationComplete={() => {
							setLoading(false);
							setLocomotive(true);
						}}
					>
						<AnimatedLetters text="discover" onAnimationComplete={() => setVisible(false)} />
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
}

export default Loader;
