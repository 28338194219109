import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

function SlicingText({ text, delay = 0, duration = 1, style }) {
	return (
		<Text
			initial={{
				clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
			}}
			animate={{
				clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
				transition: {
                    ease: "easeInOut",
					delay: delay,
					duration: duration,
				},
			}}
            style={style}
		>
			{text}
		</Text>
	);
}

export default SlicingText;

const Text = styled(motion.h1)`
	user-select: none;
`
